<template>
  <div class="home">


<!--
  https://cdn.tirix.io/images/stock/erwan-hesry-RJjY5Hpnifk-unsplash.jpg
-->

  <v-parallax
    src="https://cdn.tirix.io/images/stock/50/petr-magera-8_Qei5_ShTo-unsplash.jpg"
    :height="windowHeight"
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        class="text-center"
        cols="12"
      >
        <img src="https://cdn.tirix.io/logo/tirix-logo-full.svg" style="max-width: 400px;" />
        <h4 class="subheading">
          WAREHOUSE MANAGEMENT <span class="primary--text text--lighten-1">RƐINVENTED</span>
        </h4>
      </v-col>
    </v-row>
  </v-parallax>



  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data: () => ({
    windowHeight: window.innerHeight
  }),
    mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },

  methods: {  
    onResize() {
      this.windowHeight = window.innerHeight
    }
  }
}
</script>
