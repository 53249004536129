import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: colors.green.darken3, // #2E7D32
            secondary: colors.red.darken3, // #??????
            accent: colors.blue.darken3, // #1565C0
          },
        },
      },
    });
