<template>
  <v-app>
    <v-app-bar
      app
    >
      <div class="d-flex align-center">
        <v-img
          alt="Tirix Logo"
          class="shrink mr-2 d-none d-md-block"
          contain
          src="./assets/tirix-logo-full.svg"
          transition="scale-transition"
          width="100%"
          max-width="120"
        />
        <v-img
          alt="Tirix Logo"
          class="shrink mr-2 d-lg-none"
          contain
          src="./assets/tirix-logo-small.svg"
          transition="scale-transition"
          width="28"
        />
      </div>

        <v-tabs align-with-title
          slider-color="primary"
          v-model="tab"
          show-arrows
        >
          <v-tab>Tirix</v-tab>
          <v-tab>Platform</v-tab>
          <v-tab>Developers</v-tab>
          <v-tab>About</v-tab>
        </v-tabs>

      <v-spacer></v-spacer>
      
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            href="https://tirix.freshdesk.com/support/home"
            target="_blank"
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-comment-question-outline</v-icon>
          </v-btn>
        </template>
        <span>Helpdesk</span>
      </v-tooltip>

      



      <v-btn
        href="https://tenant.tirix.io/"
        color="primary"
        text
      >
        <span class="mr-2">Account</span>
        <v-icon>mdi-login-variant</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>

  <v-footer
    color="blue-grey darken-4 white--text"
    padless
    class="pt-4"
  >
    <v-row
      justify="center"
      no-gutters
    >
 

      <v-col
        class="py-4 text-center white--text"
        cols="12"
      >
      <strong>EXTERNAL</strong>
      <div class="mt-2">
        <v-btn
            class="ma-1"
            color="grey"
            plain
            href="https://status.tirix.io/"
            target="_blank"
        >
          <v-icon left>
            mdi-list-status
          </v-icon>
          System status
        </v-btn>
        <v-btn
            class="ma-1"
            color="grey"
            plain
            href="https://tirix.freshdesk.com/support/home"
            target="_blank"
        >
          <v-icon left>
            mdi-comment-question
          </v-icon>
          Helpdesk
        </v-btn>
        <v-btn
            class="ma-1"
            color="grey"
            plain
            href="https://developers.tirix.io/"
            target="_blank"
        >
          <v-icon left>
            mdi-api
          </v-icon>
          API documentation
        </v-btn>
      </div>
      </v-col>

      <v-col
        class="grey darken-4 py-4 text-center white--text"
        cols="12"
      >
        <a href="/privacy" class="white--text">Privacy policy</a> | Copyright &copy; {{ new Date().getFullYear() }} — <strong>Tirix</strong>
      </v-col>
    </v-row>
  </v-footer>

<!--
    <footer>

    <div
      class="d-flex justify-space-between"
    >
      <div
        v-for="n in 3"
        :key="n"
      >
        justify-space-between
      </div>
    </div>

    </footer>
    -->
  </v-app>
</template>

<script>

import router from './router'



//router.push('home')

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    tab: null,
    currentRoute: router.currentRoute
  }),

  watch: {
    $route: function (val, oldVal) {
        console.log('route changed');
        console.log(oldVal);
        if (oldVal.name === null)
        {
          //first-time initialization, set correct tab
          if (val.path == '/') {
            this.tab = 0;
          }
          else if (val.path == '/platform') {
            this.tab = 1;
          }
          else if (val.path == '/developers') {
            this.tab = 2;
          }
          else if (val.path == '/about') {
            this.tab = 3;
            console.log('route is about');
          }
          return;
        }
        
        
        window.scrollTo({top: 0, left: 0, behavior: "auto"});

    },
    tab: function (val, oldVal) {
      if (oldVal === null)
      {
        return;
      }

      if (val == 0) {
        if (router.currentRoute.path != '/')
          router.push('/', undefined, { scroll: true });
      }
      else if (val == 1) {
        if (router.currentRoute.path != '/platform')
          router.push('platform', undefined, { scroll: true });
      }
      else if (val == 2) {
        if (router.currentRoute.path != '/developers')
          router.push('developers', undefined, { scroll: true });
      }
      else if (val == 3) {
        if (router.currentRoute.path != '/about')
          router.push('about', undefined, { scroll: true });
      }
    },
    currentRoute: function(val) {
      console.log('currentRout changed');
      console.log(val);
    },
  }
};
</script>
